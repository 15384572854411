body {
    background-image: url("../images/bg.png");
    background-repeat: repeat;
    background-size: 170px;
}

h1 {
    font-size: 24px;
    font-weight: bold;
    color: #353535;
    padding: 10px 0;
}

p {
    color: #777777;
}

.bg-primary-light {
    background: $primary-light;
}

span {
    .vertnav-cat {
        padding: 10px;
        border: 1px solid #c1c1c1;
        border-bottom: 0;

        span::before {
            content: "";
            display: inline-block;
            background: url("./../images/feko-arrow-small.png");
            background-repeat-y: none;
            height: 13px;
            width: 16px;
            background-position: center left;
            margin-bottom: -1px;
            margin-right: 5px;
        }
    }
}

.attr-omschr {
    .horizontal {
        position: absolute;
        right: 50%;
        padding-left: 84px;
        font-weight: bold;
        color: #333333;
        font-size: 15px;
        margin-bottom: 10px;
        z-index: 99;
        padding-bottom: 4px;
    }

    .vertical {
        transform: rotate(90deg);
        position: absolute;
        top: 150px;
        left: -10px;
        font-weight: bold;
        color: #333333;
        font-size: 15px;
        z-index: 10;
    }
}

.product-view {
    position: relative;
    display: block;
    margin-bottom: 20px;
    padding: 10px;

    .product-name {
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: normal;
    }

    .product-essential {
        padding-bottom: 0;
        margin-bottom: 0;

        form {
            .product-info {
                @include media-breakpoint-up(sm) {
                    display: flex;
                }
            }

            .product__add-to-cart {
                text-align: right;
            }

            .product-img-box {
                text-align: center;
                margin-bottom: 5px;
                width: 250px;

                @include media-breakpoint-down(xs) {
                    margin-left: auto;
                    margin-right: auto;
                }

                .product-box-customs {
                    width: 220px;
                    clear: both;

                    .product-image {
                        overflow: inherit;
                        position: relative;
                        height: auto;
                        border: 1px solid #c6c5c5;
                        background: url(https://fekobv.nl/skin/frontend/feko/default/images/opc-ajax-loader.gif) no-repeat center center;
                    }
                }
            }

            .product-shop {
                .notloggedin-notify {
                    color: #e8413e;
                    margin-top: 0;
                    padding-bottom: 20px;
                }
            }

            .amount-input {
                width: 60px;
                text-align: center;
            }

            .products-show-stock-legenda {
                p {
                    margin-bottom: 0;
                    color: black;
                }
            }

            .no-display {
                display: none;
            }

            .clearer:after {
                box-sizing: border-box;
                display: block;
                content: "";
                clear: both;
                font-size: 0;
                line-height: 0;
                height: 0;
                overflow: hidden;
            }

            .product-options {
                position: relative;
                padding-left: 25px;
                padding-right: 25px;
                padding-bottom: 25px;
                padding-top: 5px;
                box-sizing: border-box;

                clear: both;
                margin: 0 0 18px;
                background: #fbfbfb;
                border-bottom: 0;
                margin-bottom: 0;
                border-radius: 5px 5px 0 0;

                #product-matrix-std {
                    margin-top: 30px;
                    margin-left: 15px;
                    position: relative;
                    overflow-x:auto;
                    width: 100%;

                    ul {
                        list-style: none;
                        margin: 0;

                        li {
                            text-align: center;
                            vertical-align: middle;
                            margin: 0 0 5px 0;
                        }
                    }

                    img:disabled {
                        cursor: default;
                        opacity: .5;
                    }

                    img {
                        display: inline-block;
                        float: none;
                        margin: 0;
                    }

                    #product-matrix-fixedleft {
                        left: 0;

                        ul {
                            li {
                                font-weight: bold;
                            }
                        }
                    }

                    #product-matrix-fixedright {
                        right: 0;
                    }

                    #product-matrix-fixedleft, #product-matrix-fixedright {
                        position: absolute;
                        top: 0;
                        z-index: 10;
                    }

                    #product-matrix {
                        width: auto;

                        .owl-wrapper-outer {
                            .owl-wrapper {
                                .owl-item {
                                    li {
                                        border-bottom: 1px solid #cccccc !important;
                                        position: relative;

                                        .first {
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .owl-carousel {
                        position: relative;

                        .owl-wrapper-outer {
                            overflow: hidden;
                            position: relative;
                            width: 100%;
                        }
                    }

                    .owl-carousel .owl-item {
                        float: left;
                    }

                    .required {
                        color: #e8413e;
                    }

                    input:disabled {
                        color: black !important;
                    }
                }
            }
        }
    }
}

.categories {
    padding-left: 5px;
    padding-right: 5px;
}

.categories__category-block {
    text-align: center;
    margin-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;

    @include media-breakpoint-down(xs) {
        max-width: 250px;
    }

    a {
        display: block;
    }

    img {
        border: 1px solid #c1c1c1;
        width: 100%;
        height: 200px;
        object-fit: contain;
        display: block;
        border-radius: 0;
        background-color: #fff;
    }

    .products-show-stock-legenda {
        font-size: 13px;
    }
}

.categories__category-title {
    font-size: 14px;
    text-align: center;
    min-height: 55px;
    background: white;
    margin: 0;
    padding: 5px;
    display: block;
    border: 1px solid #c1c1c1;
    border-top: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &--product {
        -webkit-line-clamp: 3;
        min-height: 78px;
    }
}

.categories__product-buy-now-wrapper {
    border: 1px solid #c1c1c1;
    border-top: 0;
    background: white;
    padding: 5px;
}

.categories__product-price {
    font-size: 13px;
}

.categories__product-buy-now-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.categories__product-buy-now-form form {
    display: flex;
    gap: 2px;
}

/* Bootstrap override */
.list-group {
    .list-group-item {
        background-color: #efefef;
        a {
            p {
                color: black;
            }
        }
    }

    .currentActive {
        font-weight: bold;
        background-color: #dfdfdf;
    }

    .selected-sub {
        font-weight: bold;
        background-color: #f3f3f3 !important;
    }

    .list-sub-item,
    .list-sub-sub-item,
    .list-sub-sub-sub-item,
    .list-sub-sub-sub-sub-item {
        background-color: #fafafa;
    }

    .list-sub-sub-item a {
        padding-left: 10px;
    }

    .list-sub-sub-sub-item a {
        padding-left: 12px;
    }

    .list-sub-sub-sub-sub-item a {
        padding-left: 16px;
    }

    .list-sub-sub-sub-sub-sub-item a {
        padding-left: 18px;
    }

    .list-group-item .inactive {
        a {
            p {
                background-color: lightgray;
            }
        }
    }
}

.list-group--hide-mobile {
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.page-content {
    padding-bottom: 2.5rem;
}

.app {
    position: relative;
    min-height: 100vh;
}


.order-total-costs {
    background-color: #E3E3E3;
    color: black;
    margin-right: 0;
    margin-left: auto;
}

.hr-total {
    width: 250px;
    height:1px;
    border: none;
    background-color: black;
}

.dashboard-card {
    width: 200px;
    height: 100px;
    border-radius: 10px;
}

// testing
ul#more-nav, ul#nav-bar-filter {
    display: inline-block;
    vertical-align: top;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
li {
    padding: 4px 8px 4px 8px;
    margin: 0;
}
#nav-bar-filter {
    overflow: hidden;
}
#nav-bar-filter li {
    display: inline-block;
    font-weight: bold;
    padding: 0;
    white-space: nowrap;
}
.filter-wrapper {
    width: 100%;
    background: #eee;
    padding: 5px 10px 5px 10px;
    display: flex;
}
#more-nav {
    width: 60px !important;
    float: right;
    visibility: hidden;
    white-space: nowrap;;
}
#more-nav:hover {
    .subfilter {
        position: absolute;
        z-index: 10;
        background-color: $primary-light;
        top: unset;
        left: unset;
    }
}
.subfilter{
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.big-dot {
    height: 50px;
    width: 50px;
    background-color: $primary;
    border-radius: 50%;
    display: inline-block;
}

.extra-big-dot {
    height: 80px;
    width: 80px;
    background-color: $primary;
    border-radius: 50%;
    display: inline-block;
}

.dot-text-middle {
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
    font-size: 25px;
}

.available {
    background: rgb(0, 211, 0);
}

.delivery-time {
    background: orange;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: initial !important;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-selection--multiple {
    height: 50px !important;
    //max-width: 300px !important;
    width: 100%;
    margin-right: 0px !important;
}
.select2-selection__arrow, .select2-selection__choice {
    display: none;
}

.short-description {
    margin-bottom: 15px;
}

.product-price {
    > h5 > span {
        color: #f7445d;
    }
}

.product-name {
    > h1 {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.product-options-bottom .price-box, .product-options-bottom .add-to-cart label, .product-options-bottom .add-to-cart #qty {
    display: none;
}

.col-6, .col-md-12 {
    margin-bottom: 15px;
}

.thumbnail-row {
    margin: 5px 0px 0px 5px;
}

.thumbnail-item {
    margin: 0px 5px 5px 5px;
}

#searchbar + span  > span > span > ul > li {
    display: none !important;
}

#popup-img {
    position: absolute;
    z-index: 100;
    left: 220px;
    width: 350px;
    height: 350px;
    box-shadow: grey;
}

#big-img, .small-img {
    cursor: pointer;
}

/* Erik's changes */
.categories-home .img-thumbnail {
    padding: 0.25rem;
    background-color: #f8fafc;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    height: auto;
    float: right;
    max-height: 100px;
    max-width: 100px;
  }

  .mt-4,
  .my-4 {
    margin-top: 10px !important;
  }

  .m-5 {
    margin: 1rem !important;
  }

  .mt-5,
  .my-5 {
    margin-top: 1rem !important;
  }

  .mr-5,
  .mx-5 {
    margin-right: 1rem !important;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 1rem !important;
  }

  .ml-5,
  .mx-5 {
    margin-left: 1rem !important;
  }

  .p-4 {
    padding: 1rem !important;
  }

  .header .hero .cart .icon {
    display: block;
    padding: 5px 10px;
  }

  .mt-3,
  .my-3 {
    margin-top: 10px !important;
  }

  .page-content-wrapper h1 {
    margin-bottom: 10px;
    padding-bottom: 0;
  }

  .container-fluid hr {
    display: none;
  }

  .container-fluid li {
    padding: 0px 2px;
  }

  .search-items-order .col-md-6 {
    padding: 10px 0 !important;
  }

  .label-links,
  .label-rechts {
    padding-right: 10px;
  }
  .padding-top {
    margin-top: 10px;
  }

  #productsindextable {
    position: relative;
    max-width: 100% !important;
  }

  .card.bg-login {
    min-height: 359.06px !important;
    background: url(/images/bg-login.gif?40b83e64dc1d53da2faf0e410e593a90)
      no-repeat right bottom #ffffff;
  }

  h2,
  .h2 {
    font-size: 1.4rem;
  }

  .product-img-box .img-thumbnail {
    max-height: none !important;
    max-width: none !important;
  }

  .product-img-box img {
    object-fit: contain;
  }

  .p-2 {
    padding: 0px 5px !important;
  }

  .page-content {
    padding-bottom: 30px;
  }

  .filter-wrapper {
    width: 100%;
    background: #eee;
    padding: 0 0 0 2px;
    display: flex;
  }

  .available {
    background: #00d300;
    position: relative;
    top: 3px;
  }

  .delivery-time {
    background: orange;
    position: relative;
    top: 3px;
  }

  .select2-selection__rendered {
    color: #ffffff;
    border-radius: 5px;
    overflow: auto;
    margin: 30px 0;
  }

  .pb-5,
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 0 !important;
  }

  .ml-3,
  .mx-3 {
    margin-left: 0rem !important;
  }

  .card-text {
    font-size: 14px;

    @include media-breakpoint-up(lg) {
        font-size: 12px;
    }
  }

  .topbar h3 {
    text-align: center;
    font-size: 17px !important;
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 5px;
    border: 1px solid transparent;
    border-radius: 0.25em;
  }

  .m-4 {
    margin: 10px !important;
  }

  .col-lg-10.card.m-4.p-4 {
    button.btn.btn-primary.mt-2 {
      position: relative;
      top: 12px;
    }
  }

  .mb-4,
  .my-4 {
    margin-bottom: 10px !important;
  }

  .table-responsive {
    margin-left: 0 !important;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 10px !important;
  }

  .kortingspercentage {
    margin-left: -10px;
  }

  table table {
    margin-bottom: 0 !important;
  }

  .table .no-select {
    padding-right: 0 !important;
  }

  .pb-3,
  .py-3 {
    padding: 20px 20px 20px 0;
  }

  .pagination li {
    padding: 2px !important;
    margin: 0;
  }

  .search .select2-selection.select2-selection--multiple {
    padding: 8px !important;
  }

  .categories-home .card {
      height: inherit !important;
  }

.hide-on-mobile {
    display: none;
    @include media-breakpoint-up(lg) {
        display: inherit;
    }
}

.hide-on-desktop {
    display: inherit;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.retour-ul-list ul {
    @include media-breakpoint-down(sm) {
        margin-left: 50px !important;
    }
}

.feko-people {
    .card img {
        object-fit: contain;
    }

    @include media-breakpoint-down(sm) {
        .row {
            justify-content: center;
        }

        .col-6 {
            max-width: 250px;
            flex: 0 0 250px;
            padding-right: 15px !important;
        }
    }
}


