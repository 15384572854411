.footer {
    width: 100%;
    position: relative;
    background: #373737;
    
    .footer-text {
        margin-bottom: 0 !important;
    }

    .col {
        margin-bottom: 10px;
    }

    h5 {
        display: block;
        width: 100%;

        margin-bottom: 10px;
        padding-bottom: 10px;

        color: $white;

        border-bottom: 1px solid #616161;
    }

    ul {
        display: block;
        width: 100%;

        padding-left: 0;
        list-style: none;

        &.prefix li::before {
            margin-right: 10px;

            color: $primary-light;
            font-family: "Font Awesome 5 Free";
            font-size: 14px;
            font-weight: 900;
            text-decoration: inherit;
            content: "\f0da";

            -webkit-font-smoothing: antialiased;
        }

        a, span {
            color: #a2a2a2;
            text-decoration: none;
        }

        a:hover {
            color: #ffffff;
        }
    }

    .copyright {
        width: 100%;

        padding: 10px 0;

        background: #272727;

        color: #A2A2A2;
        font-size: 12px;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: $white;
            }
        }
    }
}
