.header {

    .topbar {
        width: 100%;

        padding-top: 12px;
        padding-bottom: 12px;

        background: #efefef;

        border: 0;

        .phone {
            position: relative;
            margin-left: 30px;

            &::before {
                position: absolute;
                left: -30px;
                top: 1px;
                height: 20px;

                width: 20px;

                background: url('../../images/icon-phone.png');
                background-size: 20px;
                background-repeat: no-repeat;
                background-size: contain;

                content: '';
            }
        }

        h3 {
            margin: 0;

            color: #cd1719;
            font-size: 20px;
            font-weight: bold;
        }

        a {
            color: #333333;
            text-decoration: none;
        }

        ul {
            margin: 0;
            padding: 0;

            list-style: none;

            li {
                position: relative;
                margin-left: 30px;

                display: inline-block;

                &::before {
                    position: absolute;
                    left: -15px;
                    top: 7px;
                    height: 10px;

                    width: 10px;

                    background: url('../../images/favicon.png');
                    background-size: 10px;
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: '';
                }
            }
        }
    }

    .hero {
        width: 100%;

        padding: 20px 0;

        background: $primary-light;

        .logo {
            margin-right: 0%;
            
            img {
                width: 100%;
                max-width: 200px;
                height: auto;
            }
        }

        .search {
            max-width: 300px;
            width: 100%;

            /* Bootstrap override */
            .form-control {
                border: none;
            }

            .input-group-append {
                width: 100%;
            }

            /* Bootstrap override */
            .input-group-append .input-group-text {
                background-color: $primary;
                border: none;
                color: $white;
            }
        }

        .cart {
            /* Bootstrap override */
            .btn {
                padding: 4px;
            }
            @include media-breakpoint-up(lg) {
                display: inline-flex;
                padding: 10px;
            }

            .icon {
                display: block;
            }
        }

        .navigation {
            background: $primary;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: inline-block;

                    a {
                        display: block;
                        padding: 5px 0;

                        width: 100%;
                        height: 100%;

                        color: $white;
                        font-size: 15px;
                        text-align: center;
                        font-weight: bold;
                        text-decoration: none;

                        &:hover {
                            background: $dark;
                        }
                    }
                }
            }
        }
    }
}

.mobile-menu-button {
    display: none;
    border: 0;
    background: transparent;
    color: white;
    margin: 0 auto 10px;
    text-align: center;

    @include media-breakpoint-down(xs) {
        display: block;
    }
}

.mobile-menu {
    display: none;
}

@include media-breakpoint-down(xs) {
    .desktop-menu {
        display:none;
    }

    .mobile-menu-close-button {
        text-align: right !important;
        font-size: 24px !important;
        font-weight: normal !important;
    }

    .mobile-menu {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        transition: all 0.15s ease-in-out;
        transform: translateY(-100%);

        &.active {
            transform: translateY(0);
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}
