/* Bootstrap override */
.card {
    background: $white;

    border: 1px solid #d1cfcf;

    &.bg-primary-light {
        color: $white;

        background: $primary-light;
    }

    &-title {
        color: #777;
        font-size: 16px;
        text-transform: uppercase;
    }

    & img {
        width: 100%;
    }

    &.bg-login {
        min-height: 324px;

        background: url('../../images/bg-login.gif') no-repeat right bottom #ffffff;
    }
}

/* Bootstrap override */
a.card {
    text-decoration: none;

    cursor: pointer;

    &:hover {
        border-color: $primary;
    }
}

